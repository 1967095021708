import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  TooltipComponent,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilterBadge";
import { findUpper } from "../../../utils/Utils";
import ModalViewer from "../../plant_management/ModalViewer";
import { EquipmentAndSpareContext } from "../EquipmentSparesProvider";
import { EQUIPMENT_FIELDS, STATUSES } from "../constants";
import EquipmentAndSpareViewContainer from "../containers/EquipmentAndSpareViewContainer";
import EquipmentAndSpareFilter from "./EquipmentAndSpareFilter";
import ExportComponent from "./ExportForm";
import Head from "../../../layout/head/Head";
import { createColumnHelper } from "@tanstack/react-table";
import useBoolean from "../../../hooks/useBoolean";
import TanstackTable from "../../../components/tableV1/Table";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import SortableColumns from "../../../components/tableV1/SortableColumns";



function EquipmentAndSpareCompact(props) {
  // REFS

  const focusSearchRef = React.useRef();

  const equipmentAndSpareContext = useContext(EquipmentAndSpareContext);

  const {
    pagination,
    showFilter,
    sfState,
    isFilterApplied,
    handleApplyFilter,
    filterOptions,
    filterParams,
    equipmentList,
    isEquipmentListLoading,
    equipmentColumnSettings,
    setEquipmentColumnSettings,
    loadClientEquipmentList,
    setCurrentClientEquipment,
    updateShowListPage
  } = equipmentAndSpareContext;
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [sm, updateSm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [equipmentId, setEquipmentId] = useState("");
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);


  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  //   // Changing state value when searching name
  useEffect(() => { }, [onSearchText]);

  //   // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const plant = {};
  const supplier = {};
  const category = {};

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const columnHelper = createColumnHelper();

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
    loadClientEquipmentList({
      ...equipmentColumnSettings,
      filters: { ...equipmentColumnSettings.filters, searchText: onSearchText },
    });
  };

  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return <div data-toggle="tooltip" title={tableValue}>{tableValue} </div>;
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleNameSort = (sortOrder, field, table) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field, table: table });
    const updatedSettings = {
      ...equipmentColumnSettings,
      filters: { ...equipmentColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    handleUpdateViewSettings(updatedSettings);
    loadClientEquipmentList(updatedSettings);
    setEquipmentColumnSettings(updatedSettings);
  };

  const columns = React.useMemo(() => {
    if (equipmentColumnSettings?.fields?.length) {
      return equipmentColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }
    return [];
  }, [equipmentList?.items]);
  
  const renderTable = React.useMemo(() => {
    const columnMinWidth =[{field: "assetId", width: 400}]
    return (
      <TanstackTable
        dataSource={equipmentList?.items ?? []}
        _column={[...columns]}
        columnSettings={equipmentColumnSettings}
        columnMinWidth = {columnMinWidth}
        onColumWidthChanged={(resizedColumn) => {
          const _equipmentColumnSettings = [...equipmentColumnSettings.fields].map((ps) => {
            if (ps.field_key === resizedColumn.field_key) {
              return {
                ...ps,
                width: resizedColumn.width,
              };
            } else {
              return ps;
            }
          });
          handleUpdateViewSettings({
            // ...equipmentColumnSettings,
            entityType: "CLIENT_EQUIPMENT_AND_SPARES",
            fields: _equipmentColumnSettings,
          });
          setEquipmentColumnSettings((prev) => ({ ...prev, fields: _equipmentColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField);
        }}
      />
    );
  }, [columns, equipmentList?.items, equipmentColumnSettings]);

  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }

  const viewInventory = (equipmentId) => {
    setEquipmentId(equipmentId);
    toggleModal();
  };

  // const plantManagerSelectedFilter = filterParams.plantManager.map((item, key) => {
  //   return plantOptions.plantManager?.[plantOptions.plantManager?.findIndex((data) => data.value === item)];
  // });

  // const plantSelectedFilter = filterParams.plant.map((item, key) => {
  //   return plantOptions.plant?.[plantOptions.plant?.findIndex((data) => data.value === item)];
  // });
  const createExport = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const getExportComponent = () => {
    return (
      <ExportComponent
        plantIds={props?.plantId}
        onSuccessfulModal={onSuccessfulModal}
        filterParams={filterParams}
        setIsExportModalOpen={setIsExportModalOpen}
      />
    );
  };

  const getComponent = () => {
    return <EquipmentAndSpareViewContainer onSuccessfulModal={toggleModal} equipmentId={equipmentId} />;
  };

  const KEY_COMPONENT_OPTION = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const OWNED_BY_OPTIONS = [
    { label: "BGE Owned", value: "BGE" },
    { label: "Plant Owned", value: "PLANT" },
  ];

 //  column
 function tableColumn(columnSetting) {
  switch (columnSetting.field_key) {
    case "assetId":
      return columnHelper.accessor(columnSetting?.field_key, {
        id: columnSetting?.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => {
          return <p style={{cursor:"pointer"}} onClick={() => {
            viewInventory(info.row.original?.id);
            setCurrentClientEquipment(info.row.original);
          }}>
            {renderTableValue(info.row.original?.assets?.model)}
          </p>
        }
      });
    case "plantId":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.row.original?.plants?.name),          

      });
    case "supplier":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        enableResizing:false,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => {
          return renderTableValue(info.row.original?.assets?.manufacturers?.name)
        },
      });
    case "category":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        enableResizing:false,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => {
          return renderTableValue(info.row.original?.assets?.assetCategories?.name)
        },
      });
    case "quantity":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    case "contractualQuantity":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.getValue()),

      });
    case "isKeyComponent":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => {
          return info.getValue() ? "True" : "False"
        }
      });
    case "ownership":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    case "installedQuantity":
      return columnHelper.accessor(columnSetting.field_key, {
        enableSorting: false,
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    case "spareQuantity":
      return columnHelper.accessor(columnSetting.field_key, {
        enableSorting: false,
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.row.original?.spareQuantity)
      });
    case "externalWarehouseQuantity":
      return columnHelper.accessor(columnSetting.field_key, {
        enableSorting: false,
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    case "bgeWarehouseQuantity":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        enableSorting: false,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    case "supplierPremisesQuantity":
      return columnHelper.accessor(columnSetting.field_key, {
        enableSorting: false,
        id: columnSetting.field_key,
        header: () => columnSetting.field_key ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    case "description":
      return columnHelper.accessor(columnSetting.field_key, {
        enableSorting: false,
        id: columnSetting.field_key,
        header: () => columnSetting.field_key ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    case "partNumber":
      return columnHelper.accessor(columnSetting.field_key, {
        id: columnSetting.field_key,
        header: () => columnSetting.field_name ?? "",
        cell: (info) => renderTableValue(info.getValue()),
      });
    default:
      return columnHelper.accessor(columnSetting.field_key, {
        id: "id",
        enableSorting: false,
        header: () => columnSetting.field_key ?? "",
        cell: (info) => {
          return <p>unknown column</p>;
        },
      });
  }
}

  const manufacturerSelectedFilter = filterParams.supplier.map((item, key) => {
    return filterOptions.manufacturer?.[filterOptions.manufacturer?.findIndex((data) => data.value === item)];
  });

  const assetCategoryFilter = filterParams.category.map((item, key) => {
    return filterOptions.assetCategory?.[filterOptions.assetCategory?.findIndex((data) => data.value === item)];
  });
  const plantFilter =
    typeof filterParams.plant === "object" &&
    filterParams.plant.map((item, key) => {
      return filterOptions.plant?.[filterOptions.plant?.findIndex((data) => data.value === item)];
    });

  const selectedStatusFilter = STATUSES.find((data) => data.value === filterParams?.status);
  const keyoptionFilter = KEY_COMPONENT_OPTION.find((data) => data.value === filterParams?.isKeyComponent);

  const selectedOwnerShipFilter = OWNED_BY_OPTIONS.find((data) => data.value === filterParams?.ownership);

  for (let i = 0; i < filterOptions.plant?.length; i++) {
    plant[filterOptions.plant[i].value] = filterOptions.plant[i].label;
  }
  for (let i = 0; i < filterOptions.manufacturer?.length; i++) {
    supplier[filterOptions.manufacturer[i].value] = filterOptions.manufacturer[i].label;
  }
  for (let i = 0; i < filterOptions.assetCategory?.length; i++) {
    category[filterOptions.assetCategory[i].value] = filterOptions.assetCategory[i].label;
  }

  const sparepartsData = equipmentList?.items || [];

  const isPvPlantPage = window.location.pathname.includes("pv-plant");
  return (
    <React.Fragment>
      {!isPvPlantPage && <Head title="Brighter App | Equipment and Spares" />}
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Equipment And Spares
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {equipmentList?.pagination?.totalCount} Spare Parts.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {filterParams.status ? (
                <FilteredByList data={selectedStatusFilter.label} title={"Status"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.isKeyComponent ? (
                <FilteredByList data={keyoptionFilter.label} title={"Key Component"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.ownership ? (
                <FilteredByList data={selectedOwnerShipFilter.label} title={"Owned By"} isNotMap={true} isStr={true} />
              ) : null}
              <FilteredByList data={manufacturerSelectedFilter} title={"Supplier"} isMap={true} isStr={false} />
              {filterParams?.plant ? (
                <FilteredByList data={plantFilter} title={"Plant"} isMap={true} isStr={false} />
              ) : null}
              <FilteredByList data={assetCategoryFilter} title={"Category"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
          <Sidebar toggleState={showFilter}>
              <div>
                <EquipmentAndSpareFilter plantId={props?.plantId} />
              </div>
            </Sidebar>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <a
                      className={`btn btn-white btn-outline-light ${sparepartsData?.length > 0 ? "" : "disabled"}`}
                      type="export"
                      // isLoading={exportLoading}
                      onClick={() => createExport()}
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
          {/* <BlockHeadContent></BlockHeadContent> */}
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-spareparts-btn"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <div id="spareparts-filter" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    <div id="equipment-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                      <Icon name="view-list-fill"></Icon>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div>
            <ModalViewer
              title="Equipment and Spares Columns"
              isOpen={isColumnArrangeModalOpen}
              size="md"
              toggleModal={icamState.toggle}
              component={
                <SortableColumns
                  entityType={"CLIENT_EQUIPMENT_AND_SPARES"}
                  fields={EQUIPMENT_FIELDS}
                  updateViewSettings={async (modifiedSettings) => {
                    const updatedSettings = await handleUpdateViewSettings({
                      ...equipmentColumnSettings,
                      fields: modifiedSettings,
                    });
                    setEquipmentColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                    await loadClientEquipmentList(updatedSettings);
                  }}
                  closeModal={icamState.off}
                  visibleColumns={equipmentColumnSettings.fields}
                />
              }
            />
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      id="cancle-spareparts-search"
                      className="search-back btn-icon toggle-search  active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                        loadClientEquipmentList({
                          ...equipmentColumnSettings,
                          filters: { ...equipmentColumnSettings.filters, searchText: "" },
                        });
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      id="search-input-spareparts"
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by plant name and equipment name. Enter to search "
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                        if (e.keyCode === 88) {
                          setSearchText("");
                        }
                      }}
                    />
                    <Button id="search-text" className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isEquipmentListLoading ? <Skeleton count={10} className="w-100" /> : renderTable}

          <div className="card-inner">
            {equipmentList.pagination && equipmentList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={equipmentList.pagination.totalCount}
                paginate={(page) => {
                  loadClientEquipmentList({
                    ...equipmentColumnSettings,
                    filters: { ...equipmentColumnSettings.filters, page },
                  });
                  updateShowListPage(page);
                }} 
                currentPage={equipmentList.pagination.currentPage}
                paginatePage={(size) => {
                  updateShowListPage(size);
                  const updatedSetting = {
                    ...equipmentColumnSettings,
                    filters: { ...equipmentColumnSettings.filters, size },
                  };
                  handleUpdateViewSettings(updatedSetting);
                  setEquipmentColumnSettings(updatedSetting);
                  loadClientEquipmentList(updatedSetting);
                }} />
            ) : (
              !isEquipmentListLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="Spare Parts - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
      <ModalViewer
        size={"xl"}
        title="Equipment And Spares"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
    </React.Fragment>
  );
}

export default EquipmentAndSpareCompact;
